import React from 'react';
import SEO from '../components/App/SEO';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { graphql } from 'gatsby';

const img = '/img/gdpr/gdpr-ccpa-compliance.webp';

const gdprPage = ({data}) => (

    <Layout env={data.site.siteMetadata.env}>
        <Navbar />
        <SEO 
            postTitle='Compliance with Privacy Legislation Worldwide | BlueMail App' 
            postDescription='BlueMail complies with the highest data protection regulations including the GDPR & CCPA. We’re committed to partnering with our Customers and Users to be fully transparent in our approach to these regulations'
            postImage='/img/OG/og_image-gdpr-ccpa-compliance.png'
            postURL='gdpr'
            postSEO
        />
        <div className="container pt-120 pb-100">            
            <div className='row'>
                <div className='col-12 mb-30'>
                    <img src={img} alt='BlueMail Plan for GDPR' style={{width: '100%'}}/>
                </div>
                <div className='col-12'>
                    <h3>Compliance with Privacy Legislation - GDPR & CCPA</h3>
                    <br></br>
                    <h5>Our Commitment to You</h5>
                    <p>We’re committed to partnering with our Customers and Users to be fully transparent in our approach to the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA). The GDPR is a very comprehensive EU data privacy law which when into effect on May 25, 2018 and the CCPA is a comprehensive privacy law passed in California (USA), that went into effect on January 1st, 2020.</p>
                    <p>Besides strengthening and standardizing user data privacy across the EU and US, it required new and additional obligations on all organizations that handle EU and Californian citizens’ personal data, regardless of where the organizations themselves are located.</p>
    
                    <h3>Protection of Your Data</h3>
                    <p>We’re constantly striving to provide a seamless, integrated experience to help you and your team work smarter and faster. To that end, we want to give you an overview of our updated privacy policy. It’s more user-friendly and addresses the new data regulations.</p>
                    <p>The primary goal of GDPR & CCPA is to grant EU and California based users (respectively) full transparency and control over the data which they share and how it is used, therein preventing any risk of misuse when consent isn’t explicitly granted. Even though the regulations only impacted Europeans and Californians, we believe data security is still important wherever you happen to call home.</p>
                    <p>Aligned with this same goal and requirement, we introduced a few changes on how the data of our users is handled, inclusive of collection, processing, control and access. Our Privacy Policy was updated to account for both GDPR and CCPA provisions. We will also make the policies more transparent by providing specific details on our data processing activities.</p>
                    <p>This privacy policy went into effect on May 25, 2018. For questions please reach out to us using the contact information provided in the privacy policy.</p>
    
                    <h3>Data Management Tools</h3>
                    <p>User have requested tools to help them comply with the GDPR and CCPA, and we’re happy to say that we’ve built a dedicated tool.</p>
                    <p><a style={{color: '#1F6BF1'}} href='https://panel.bluemail.me/'>Account deletion tool</a> Help users to delete personal information, such as names and email addresses, from BlueMail.</p>
    
                    <h3>Stay Updated</h3>
                    <p>Fulfilling our privacy and data security commitments is important to us. To learn more about GDPR, visit the <a style={{color: '#1F6BF1'}} href='https://ec.europa.eu/info/law/law-topic/data-protection_en'>official GDPR website of the EU.</a></p>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>  
);

export default gdprPage;

export const query = graphql`
query GdprPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
